import { createTheme } from '@mui/material/styles';

// Define the palette
const palette = {
	primary: {
		main: '#FFA939',
		light: '#FFA939',
		dark: '#B27627',
		contrastText: '#FFFFFF',
	},
	secondary: {
		main: '#47A8BE',
	},
	text: {
		main: '#0A0D14',
		light: '#525866',
	},
	neutral: {
		main: '#717A8E',
		light: '#E4E7EC',
		lighter: '#F6F8FA',
	},
	danger: {
		main: '#FE1B1B',
		contrastText: '#FFFFFF',
	},
};

// Define the typography
const typography = {
	fontFamily: 'Roboto, sans-serif',
	h2: { fontSize: '1.5rem', fontWeight: 700, color: palette.text.main },
	h3: { fontSize: '1.125rem', fontWeight: 500, color: palette.text.main },
	subtitle1: {
		fontSize: '1rem',
		fontWeight: 500,
		textAlign: 'center',
		lineHeight: '1.5rem',
		color: palette.text.light,
	},
	body1: { fontSize: '0.875rem', fontWeight: 400, color: palette.text.light },
	footnote: { fontSize: '0.75rem', fontWeight: 300, color: palette.text.light },
};

// Define the components
const components = {
	MuiButton: {
		styleOverrides: {
			root: {
				textTransform: 'none', // disables uppercase
			},
		},
	},
};

// Create the theme instance
const theme = createTheme({
	palette,
	typography,
	components,
});

export default theme;
