const sanitizeFieldName = (name) => {
	return name
		.toLowerCase() // Convert the string to lowercase
		.normalize('NFD') // Decompose accented chars into base + diacritical marks
		.replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
		.replace(/[^a-z0-9\s]/g, '') // Remove all non-alphanumeric chars except spaces
		.replace(/\s+/g, '-'); // Replace spaces (one or more) with a single '-'
};

export default sanitizeFieldName;
