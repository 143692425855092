import { useContext, useState } from 'react';
import {
	Dialog,
	DialogContent,
	Button,
	Typography,
	Box,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	TextField,
	Backdrop,
	CircularProgress,
} from '@mui/material';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import CustomDatasetPreviewTable from './CustomDatasetPreviewTable';
import { createDataset } from '../../api_helper/api';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';

const CreateDatasetDialog = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [isLoading, setIsLoading] = useState(false);

	const closeDialogHandler = () => {
		customDatasetsContext.setView(null);
		customDatasetsContext.setCustomDataset(null);
	};

	const datasetNameInputChangeHandler = (event) => {
		customDatasetsContext.setCustomDataset((prevState) => ({
			...prevState,
			...prevState.customDataset,
			name: event.target.value,
		}));
	};

	const joinFieldsChangeHandler = (event) => {
		// Get the names of the selected fields
		const selectedFieldNames = event.target.value.map((field) => field.name);

		// Update the isJoinField property of each field in the custom dataset
		customDatasetsContext.setCustomDataset((prevState) => ({
			...prevState,
			fields: prevState.fields.map((field) => ({
				...field,
				isJoinField: selectedFieldNames.includes(field.name),
			})),
		}));
	};

	const sportSelectChangeHandler = (event) => {
		customDatasetsContext.setCustomDataset((prevState) => ({
			...prevState,
			tags: { ...prevState.tags, sport: event.target.value },
		}));
	};

	const createDatasetClickHandler = async () => {
		// Validate that a dataset name has been entered
		if (!customDatasetsContext.customDataset.name) {
			snackbarAlertContext.setSnackbarAlert({
				msg: 'Please enter a dataset name.',
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
			return;
		}

		// Validate that a sport has been selected
		if (!customDatasetsContext.customDataset.tags.sport) {
			snackbarAlertContext.setSnackbarAlert({
				msg: 'Please select a sport.',
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
			return;
		}

		// Validate that join fields have been selected
		if (!customDatasetsContext.customDataset.fields.some((field) => field.isJoinField)) {
			snackbarAlertContext.setSnackbarAlert({
				msg: 'Please select at least one join key.',
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
			return;
		}

		// Render loading animation
		setIsLoading(true);

		// Create the dataset in the database
		await createDataset({
			name: customDatasetsContext.customDataset.name,
			tags: {
				sport: customDatasetsContext.customDataset.tags?.sport,
				datasetGroup: customDatasetsContext.customDataset.tags?.sport,
			},
			fields: customDatasetsContext.customDataset.fields,
			rows: customDatasetsContext.customDataset.rows,
		});

		// Remove loading animation
		setIsLoading(false);

		// Close the dialog
		closeDialogHandler();

		// Refresh the list
		customDatasetsContext.setCustomDatasets(undefined);
	};

	return (
		Boolean(customDatasetsContext.view === 'create') && (
			<>
				{/* Create Dataset Dialog */}
				<Dialog
					open={true}
					onClose={closeDialogHandler}
					fullWidth
					maxWidth="xl"
					PaperProps={{
						style: {
							maxHeight: '50rem',
						},
					}}
				>
					<DialogContent>
						<Typography variant="h2">Upload Private Dataset</Typography>
						<Typography variant="footnote" sx={{ display: 'block', marginTop: '0.5rem' }}>
							All uploaded files must include headers and be in either Excel or CSV format.
						</Typography>

						<Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'end', marginTop: '1rem' }}>
							<FormControl style={{ minWidth: '15rem', marginRight: '1rem', flexGrow: '1' }}>
								<TextField
									label="Dataset Name"
									fullWidth
									onChange={datasetNameInputChangeHandler}
									value={customDatasetsContext.customDataset.name}
								/>
							</FormControl>

							<FormControl fullWidth sx={{ width: '20rem' }}>
								<InputLabel>Select Sport</InputLabel>
								<Select
									value={customDatasetsContext.customDataset.tags?.sport || ''}
									onChange={sportSelectChangeHandler}
									label="Select Sport"
								>
									<MenuItem value="NFL">NFL</MenuItem>
									<MenuItem value="NBA">NBA</MenuItem>
									<MenuItem value="NHL">NHL</MenuItem>
									<MenuItem value="MLB">MLB</MenuItem>
								</Select>
							</FormControl>
						</Box>

						<Box
							sx={{
								width: '100%',
								display: 'flex',
								flexWrap: 'wrap',
								border: (theme) => `1px solid ${theme.palette.neutral.light}`,
								borderRadius: '0.5rem',
								marginTop: '1rem',
								overflow: 'hidden',
							}}
						>
							<Box sx={{ padding: '1rem' }}>
								<Typography variant="h3">Select a Key Column</Typography>
								<Typography variant="footnote">
									Review your uploaded data and select a Key column to link with other data sets.
								</Typography>
							</Box>

							<CustomDatasetPreviewTable />

							<FormControl sx={{ width: '100%', maxWidth: '25rem', margin: '1rem' }}>
								<InputLabel>Select Join Keys</InputLabel>
								<Select
									value={customDatasetsContext.customDataset.fields?.filter((e) => e.isJoinField) || []}
									multiple
									onChange={joinFieldsChangeHandler}
									placeholder="Join Keys"
									displayEmpty
									label="Select Join Keys"
								>
									{customDatasetsContext.customDataset.fields?.map((field) => (
										<MenuItem key={field.name} value={field}>
											{field.displayName}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
					</DialogContent>

					{/* Action buttons */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'end',
							padding: '1.25rem 1.5rem',
							borderTop: (theme) => `1px solid ${theme.palette.neutral.light}`,
						}}
					>
						<Button variant="outlined" color="neutral" onClick={closeDialogHandler} sx={{ marginRight: '0.75rem' }}>
							Cancel
						</Button>
						<Button variant="contained" color="primary" onClick={createDatasetClickHandler}>
							Create Dataset
						</Button>
					</Box>
				</Dialog>

				{/* Loading Spinner */}
				<Backdrop open={isLoading} sx={{ width: '100vw', height: '100vh', zIndex: '10000', color: '#FFFFFF' }}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</>
		)
	);
};

export default CreateDatasetDialog;
