import React, { useState } from 'react';

const CustomDatasetsContext = React.createContext();

// Context used for custom datasets page.
export const CustomDatasetsContextProvider = (props) => {
	const [customDatasets, setCustomDatasets] = useState();
	const [searchQuery, setSearchQuery] = useState('');
	const [customDataset, setCustomDataset] = useState(null);
	const [view, setView] = useState(null);

	return (
		<CustomDatasetsContext.Provider
			value={{
				customDatasets,
				setCustomDatasets,
				searchQuery,
				setSearchQuery,
				customDataset,
				setCustomDataset,
				view,
				setView,
			}}
		>
			{props.children}
		</CustomDatasetsContext.Provider>
	);
};

export default CustomDatasetsContext;
