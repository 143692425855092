import { useEffect, useContext } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import NoCustomDatasets from './NoCustomDatasets';
import CustomDatasets from './CustomDatasets';
import { getDatasets } from '../../api_helper/api';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import { UserProfileContext } from '../../UserProfileContext';
import CreateDatasetDialog from './CreateDatasetDialog';
import OverwriteDatasetDialog from './OverwriteDatasetDialog';
import EditDatasetDialog from './EditDatasetDialog';
import DeleteDatasetDialog from './DeleteDatasetDialog';

const MyData = () => {
	const user = useContext(UserProfileContext)[0];
	const customDatasetsContext = useContext(CustomDatasetsContext);

	// Fetch user's custom datasets
	useEffect(() => {
		const fetchCustomDatasets = async () => {
			// Fetch datasets created by user
			const datasets = (await getDatasets({ userId: user._id }))?.data?.datasets;

			// Set fetched datasets in context
			customDatasetsContext.setCustomDatasets(datasets);
		};

		if (customDatasetsContext.customDatasets === undefined) {
			fetchCustomDatasets();
		}
	}, [customDatasetsContext.customDatasets]);

	return (
		<ThemeProvider theme={theme}>
			{customDatasetsContext.customDatasets ? (
				<>
					{customDatasetsContext.customDatasets.length ? <CustomDatasets /> : <NoCustomDatasets />}
					<CreateDatasetDialog />
					<OverwriteDatasetDialog />
					<EditDatasetDialog />
					<DeleteDatasetDialog />
				</>
			) : (
				// Loading Spinner
				<Box
					sx={{
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<CircularProgress color="secondary" />
				</Box>
			)}
		</ThemeProvider>
	);
};

export default MyData;
