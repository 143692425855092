import { useContext, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Menu, IconButton, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import DeleteDatasetButton from './DeleteDatasetButton';
import OverwriteDataButton from './OverwriteDataButton';
import EditDatasetButton from './EditDatasetButton';

const styles = {
	column: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
};

const CustomDatasetsTable = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);

	const [anchorEl, setAnchorEl] = useState(null);

	// Menu template for each row
	const menuTemplate = (dataset) => {
		return (
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<IconButton
					onClick={(event) => {
						setAnchorEl(event.currentTarget);
						customDatasetsContext.setCustomDataset(dataset);
					}}
				>
					<MoreVertIcon />
				</IconButton>
			</div>
		);
	};

	// Filter datasets based on search query
	const datasets = customDatasetsContext.customDatasets
		.filter((dataset) => dataset.name.toLowerCase().includes(customDatasetsContext.searchQuery.toLowerCase()))
		.map((dataset) => {
			const keys = dataset.fields
				.filter((field) => field.isJoinField)
				.map((field) => field.displayName || field.name)
				.join(', ');

			return {
				...dataset,
				tags: {
					sport: dataset.tags.sport,
				},
				keys,
			};
		});

	return (
		<div style={{ width: '100%', overflowX: 'auto' }}>
			{/* Menu list for each row */}
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl && customDatasetsContext.customDataset)}
				onClose={() => setAnchorEl(null)}
			>
				<EditDatasetButton />

				<OverwriteDataButton />

				<Divider />

				<DeleteDatasetButton />
			</Menu>

			{/* Custom Datasets Table */}
			<DataTable value={datasets} style={{ minWidth: '60rem' }}>
				<Column field="name" header="Name" sortable style={styles.column} />
				<Column field="tags.sport" header="Sport" sortable style={{ ...styles.column, width: '8rem' }} />
				<Column field="keys" header="Keys" style={styles.column} />
				<Column
					field="creationDate"
					header="Initial Upload"
					style={styles.column}
					body={(e) => new Date(e.creationDate).toLocaleString()}
					sortable
				/>
				<Column
					field="lastUpdatedDate"
					header="Last Update"
					style={styles.column}
					body={(e) => new Date(e.lastUpdatedDate).toLocaleString()}
					sortable
				/>
				<Column header="Actions" body={menuTemplate} style={{ ...styles.column, width: '6rem' }} />
			</DataTable>
		</div>
	);
};

export default CustomDatasetsTable;
