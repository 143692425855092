const isCsvCompatible = (jsonData) => {
	// 1. The top-level value must be an array.
	if (!Array.isArray(jsonData)) {
		return false;
	}

	// 2. If it’s an empty array, decide if you consider that valid or not.
	// For some CSV use cases, an empty array might be allowed. Here, let’s allow it.
	if (jsonData.length === 0) {
		return true;
	}

	// 3. Ensure every element in the array is an object with the same keys.
	// Get the keys from the first object.
	const expectedKeys = Object.keys(jsonData[0]);

	for (const item of jsonData) {
		// Check that item is a plain object (not null, not an array).
		if (typeof item !== 'object' || item === null || Array.isArray(item)) {
			return false;
		}

		// Check that the keys match exactly (same length, same names).
		const itemKeys = Object.keys(item);
		if (itemKeys.length !== expectedKeys.length || !expectedKeys.every((key) => itemKeys.includes(key))) {
			return false;
		}
	}

	return true;
};

const validateJson = (data) => {
	const errors = [];

	// Check if the JSON data is compatible with CSV
	if (!isCsvCompatible(data)) {
		errors.push('JSON data is not compatible with CSV format.');
	}

	// Get fields from the first row of data
	const fields = Object.keys(data[0]);

	// Validate data has at least 1 column and 1 row
	if (fields.length === 0) {
		errors.push('CSV must have at least 1 column (missing headers).');
	}
	if (data.length === 0) {
		errors.push('CSV must have at least 1 row of data (besides the header).');
	}

	// Validate all cells in the first row having header name values (no empty headers)
	if (fields.some((header) => !header || !header.trim())) {
		errors.push('All header cells in the first row must have a name (no blanks).');
	}

	// Validate all header names are unique
	const uniqueHeaders = new Set(fields.map((h) => h.trim().toLowerCase()));
	if (uniqueHeaders.size !== fields.length) {
		errors.push('All header names must be unique (case-insensitive).');
	}

	// Validate all values in each column have the same data type (ignoring null/undefined)
	// The header cell is always a string, which is allowed to be different.
	if (data.length > 0) {
		fields.forEach((field) => {
			let detectedType = null; // We'll store the first non-null type we find

			for (let i = 0; i < data.length; i++) {
				const value = data[i][field];

				// null or undefined is allowed; skip it
				if (value === null || value === undefined) {
					continue;
				}

				const currentType = typeof value; // e.g. "string", "number", "boolean"

				// If it's the first non-null/undefined value, set the column's type
				if (!detectedType) {
					detectedType = currentType;
				} else {
					// Compare the current cell's type with the column's detected type
					if (currentType !== detectedType) {
						errors.push(
							`Column "${field}" has inconsistent data types. 
               Expected all "${detectedType}" but found "${currentType}".`,
						);
						break; // No need to check further once an inconsistency is found
					}
				}
			}
		});
	}

	return errors;
};

export default validateJson;
