import { useContext, useEffect, useState } from 'react';
import { makeStyles, Grid, Typography, CircularProgress } from '@material-ui/core';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import { getDataSourcesAvailableFields, getLatestSeasonDatasources, getDatasets } from '../../../../api_helper/api';
import Dataset from './Dataset';
import { UserProfileContext } from '../../../../UserProfileContext';

const useStyles = makeStyles((theme) => ({
	datasetsListWrapper: {
		height: '100%',
		marginBottom: '1rem',
		display: 'flex',
		flexDirection: 'column',
	},
	selectedDataHeader: {
		fontSize: '1.1rem',
		fontWeight: 'bold',
	},
	datasetsList: {
		width: '100%',
		maxHeight: '30rem',
		flexGrow: '1',
		margin: '0',
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#ECECEC',
		borderRadius: '0.5rem',
		overflowY: 'scroll',
		listStyle: 'none',
		'& > li': {
			width: '100%',
		},
		'& > li:last-child > button': {
			border: 'none',
		},
	},
}));

// List of available datasources to choose from.
const DatasetsList = () => {
	const classes = useStyles();

	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);
	const user = useContext(UserProfileContext)[0];

	const [latestDatasets, setLatestDatasets] = useState([]);

	const getDatasetsList = async () => {
		try {
			const datasetGroups = [];
			if (createEditDataSpaceContext.newDataSpace.fantasyLeague) {
				datasetGroups.push(createEditDataSpaceContext.newDataSpace.fantasyLeague.sport);
				if (createEditDataSpaceContext.newDataSpace.fantasyLeague.api === 'Yahoo! Fantasy Sports') {
					datasetGroups.push('Yahoo' + createEditDataSpaceContext.newDataSpace.fantasyLeague.sport);
				} else {
					datasetGroups.push(
						createEditDataSpaceContext.newDataSpace.fantasyLeague.api +
							createEditDataSpaceContext.newDataSpace.fantasyLeague.sport,
					);
				}
			} else {
				datasetGroups.push(createEditDataSpaceContext.newDataSpace.sportsLeague);
			}

			let allDatasets = createEditDataSpaceContext.newDataSpace.allDatasets;
			if (allDatasets.length === 0) {
				const getDatasetsRes = await getDatasets({ groups: datasetGroups, userId: user._id });

				allDatasets = getDatasetsRes.data.datasets;

				createEditDataSpaceContext.setNewDataSpace((prevState) => ({
					...prevState,
					allDatasets,
				}));
			}

			const latestDatasetsArr = allDatasets.reduce((accumulator, dataset) => {
				// If it is not a timed dataset, add it to the array.
				if (!dataset.tags.timeFrame) {
					accumulator.push(dataset);
					return accumulator;
				}

				// If it is a seasoned dataset...
				if (Number(dataset.tags.timeFrame) > 0 && Number(dataset.tags.timeFrame) < 9999) {
					const i = accumulator.findIndex((e) => e.name === dataset.name);
					// If it's a dataset whose name exists in array.
					if (i !== -1) {
						// If it's the dataset with the higher season.
						if (Number(accumulator[i].tags.timeFrame) < Number(dataset.tags.timeFrame)) {
							accumulator[i] = dataset;
							return accumulator;
						}
					} else {
						// If it's a seasoned dataset that doesn't exist in the array.
						accumulator.push(dataset);
						return accumulator;
					}
				}

				return accumulator;
			}, []);
			setLatestDatasets(latestDatasetsArr);
		} catch (e) {
			console.log('ERROR: ', e);
		}
	};

	useEffect(() => {
		getDatasetsList();
	}, []);

	const datasetComponents = latestDatasets.map((dataset) => (
		<li key={dataset._id}>
			<Dataset dataset={dataset} />
		</li>
	));

	return (
		<Grid container item xs={12} md={7.5} className={classes.datasetsListWrapper}>
			<Typography className={classes.selectedDataHeader}>
				Choose the datasets that will serve as the foundation for your dataspace.
			</Typography>
			<ul className={classes.datasetsList}>
				{datasetComponents.length ? (
					datasetComponents
				) : (
					<CircularProgress className={classes.datasetsListLoadingIcon} />
				)}
			</ul>
		</Grid>
	);
};

export default DatasetsList;
