import { useContext } from 'react';
import { ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { getDatasetRows } from '../../api_helper/api';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';

const EditDatasetButton = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);

	const editDatasetClickHandler = async () => {
		const datasetRows = await getDatasetRows(customDatasetsContext.customDataset._id);

		customDatasetsContext.setCustomDataset((prevState) => ({
			...prevState,
			rows: datasetRows,
		}));

		customDatasetsContext.setView('edit');
	};

	return (
		<MenuItem onClick={editDatasetClickHandler}>
			<ListItemIcon>
				<SvgIcon fontSize="1rem" component={EditIcon} />
			</ListItemIcon>
			<ListItemText>Edit</ListItemText>
		</MenuItem>
	);
};

export default EditDatasetButton;
