import { Box, Typography } from '@mui/material';
import SearchBar from './SearchBar';
import CustomDatasetsTable from './CustomDatasetsTable';
import CreateDatasetButton from './CreateDatasetButton';

const CustomDatasets = () => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					width: '95%',
					maxWidth: '100rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Box
					sx={{
						width: '100%',
						padding: '1.5rem 0',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flexWrap: 'wrap',
						borderBottom: (theme) => `1px solid ${theme.palette.neutral.light}`,
					}}
				>
					<Box>
						<Typography variant="h3" sx={{ marginBottom: '0.25rem' }}>
							Uploaded Datasets
						</Typography>
						<Typography variant="body1">Easily access, organize, and update your uploaded data sets.</Typography>
					</Box>

					<CreateDatasetButton />
				</Box>

				<Box sx={{ width: '100%', margin: '1rem' }}>
					<SearchBar />
				</Box>

				<CustomDatasetsTable />
			</Box>
		</Box>
	);
};

export default CustomDatasets;
