import { useContext, useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import {
	makeStyles,
	Grid,
	Typography,
	IconButton,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	LinearProgress,
} from '@material-ui/core';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CreateEditDataSpaceContext from '../../context/CreateEditDataSpaceContext';
import MyDataSpacesViewContext from '../../context/MyDataSpacesViewContext';
import NBALogoPNG from '../../../../assets/img/NBALogoSquare.png';
import MLBLogoPNG from '../../../../assets/img/MLBLogoSquare.png';
import NFLLogoPNG from '../../../../assets/img/NFLLogoSquare.png';
import NHLLogoPNG from '../../../../assets/img/NHLLogoSquare.png';
import YahooFantasyLogoPNG from '../../../../assets/img/YahooFantasyLogo.png';
import FantraxLogoPNG from '../../../../assets/img/FantraxLogo.png';
import { trackEvent } from '../../../../utils/eventTracking';
import { UserProfileContext } from '../../../../UserProfileContext';
import { getDatasourceObjectTypeDatasources, getDataSourcesAvailableFields } from '../../../../api_helper/api';
import SnackbarAlertContext from '../../../../context/SnackbarAlertContext';
import { ObjectId } from 'bson';

const useStyles = makeStyles((theme) => ({
	dialogWrapper: {
		width: '100%',
		padding: '1.5rem',
		paddingBottom: '4.5rem',
		display: 'flex',
		flexDirection: 'column',
	},
	dialogHeaderWrapper: {
		width: '100%',
		marginBottom: '2rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	dialogHeaderText: {
		fontSize: '1.5rem',
		fontWeight: 'bold',
		marginLeft: 'auto',
	},
	dialogExitButton: {
		marginLeft: 'auto',
	},
	datasetWrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	datasetLogo: {
		height: '100px',
		marginRight: '0.8rem',
		borderRadius: '0.5rem',
	},
	datasetInfoWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
	},
	seasonSelectForm: {
		margin: '0.5rem 0',
	},
	datasetField: {
		width: '100%',
		height: 'fit-content',
		padding: '0.3rem',
		backgroundColor: '#C4C4C4',
		borderRadius: '0.2rem',
		transition: '0.3s',
		'&:hover': {
			backgroundColor: '#C4C4C4',
			filter: 'brightness(0.75)',
		},
		'& > span': {
			width: '100%',
			display: 'inline',
			color: '#000000',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
	},
	datasetFieldSelected: {
		backgroundColor: '#0DBC42',
		'&:hover': {
			backgroundColor: '#0DBC42',
			filter: 'brightness(0.75)',
		},
	},
	nextButton: {
		alignSelf: 'end',
	},
	loadingSeasonsWrapper: {
		margin: '0.75rem 0',
		'& > span': {
			margin: '0.7rem 0',
		},
	},
}));

// Dialog (modal) allowing user to select fields for each selected datasource.
const SelectDatasetFieldsDialog = (props) => {
	const classes = useStyles();
	const createEditDataSpaceContext = useContext(CreateEditDataSpaceContext);

	// User Profile Data
	const userProfile = useContext(UserProfileContext)[0];
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const myDataSpacesViewContext = useContext(MyDataSpacesViewContext);

	const [selectedDataset, setSelectedDataset] = useState(JSON.parse(JSON.stringify(props.dataset)));
	const [timeFrameDatasets, setTimeFrameDatasets] = useState([]);

	const getDatasetLogoSource = () => {
		switch (selectedDataset.tags.datasetGroup) {
			case 'NBA':
				return NBALogoPNG;
			case 'MLB':
				return MLBLogoPNG;
			case 'NFL':
				return NFLLogoPNG;
			case 'NHL':
				return NHLLogoPNG;
			case 'YahooNBA':
			case 'YahooMLB':
			case 'YahooNHL':
			case 'YahooNFL':
				return YahooFantasyLogoPNG;
			case 'FantraxNBA':
			case 'FantraxMLB':
			case 'FantraxNHL':
			case 'FantraxNFL':
				return FantraxLogoPNG;
		}
	};

	const dialogCloseButtonClickHandler = () => {
		props.setIsSelectDatasetFieldsDialogOpen(false);
	};

	const datasetChangeHandler = (e) => {
		setSelectedDataset(e.target.value);
	};

	const datasetFieldClickHandler = (e, clickedField) => {
		const updatedFields = selectedDataset.fields.map((field) => {
			if (field.name === clickedField.name) {
				field.isSelected = !field.isSelected;
			}
			return field;
		});
		setSelectedDataset((prevState) => ({
			...prevState,
			fields: updatedFields,
		}));
	};

	const nextButtonClickHandler = () => {
		const isFirstSelectedDataset = createEditDataSpaceContext.newDataSpace.selectedDatasets.length === 0;

		// Create dataset instance.
		const datasetInstance = {
			...selectedDataset,
			_id: new ObjectId().toString(),
			originDatasetId: selectedDataset._id,
			userId: createEditDataSpaceContext.newDataSpace.fantasyLeague?.userId, // Defined for fantasy leagues.
			leagueName: createEditDataSpaceContext.newDataSpace.fantasyLeague?.name, // Defined for fantasy leagues.
			leagueKey:
				createEditDataSpaceContext.newDataSpace.fantasyLeague?.league_key ||
				createEditDataSpaceContext.newDataSpace.fantasyLeague?.leagueId, // Defined for fantasy leagues.
			gameKey: createEditDataSpaceContext.newDataSpace.fantasyLeague?.league_key?.substring(0, 3), // Defined for Yahoo! fantasy leagues.
		};

		createEditDataSpaceContext.setNewDataSpace((prevState) => {
			// Append dataset instance id to the end of each field for the selected dataset.
			datasetInstance.fields = datasetInstance.fields.map((field) => ({
				...field,
				name: field.name + '__' + datasetInstance._id,
			}));

			return {
				...prevState,
				selectedDatasets: [...prevState.selectedDatasets, datasetInstance],
				views: prevState.views.filter((view) => view.name !== 'Default'),
				joinData: [
					...prevState.joinData,
					{
						_id: datasetInstance._id,
						name: datasetInstance.name,
						displayName: datasetInstance.displayName,
						api: datasetInstance.api,
						isAnchor: isFirstSelectedDataset,
						tags: datasetInstance.tags,
						userId: datasetInstance.userId,
						leagueKey: datasetInstance.leagueKey,
						gameKey: datasetInstance.gameKey,
						user: datasetInstance.user,
						originDatasetId: datasetInstance.originDatasetId,
						joins: [],
					},
				],
			};
		});

		// If it is the first dataset selected, return to datasets list. Otherwise redirect to join page.
		if (isFirstSelectedDataset) {
			props.setIsSelectDatasetFieldsDialogOpen(false);
		} else {
			myDataSpacesViewContext.changeViewHandler('datasetJoinNew');
		}
	};

	// If the dataset is a timed dataset, get the others with the same name for timeFrame select options.
	useEffect(() => {
		if (selectedDataset.tags.timeFrame) {
			const timeFrameDatasets = createEditDataSpaceContext.newDataSpace.allDatasets.filter(
				(dataset) => dataset.name === selectedDataset.name,
			);
			setTimeFrameDatasets(timeFrameDatasets);
		}
	}, []);

	// Create field pill boxes.
	const datasetFieldComponents = selectedDataset.fields.reduce((accumulator, field) => {
		!field.isExcluded &&
			accumulator.push(
				<Grid key={field.name} item xs={12} sm={6}>
					<Button
						className={classes.datasetField + (field.isSelected ? ' ' + classes.datasetFieldSelected : '')}
						onClick={(e) => {
							datasetFieldClickHandler(e, field);
						}}
					>
						{field.displayName || field.name}
					</Button>
				</Grid>,
			);
		return accumulator;
	}, []);

	return (
		<Dialog fullWidth open={props.isSelectDatasetFieldsDialogOpen}>
			<div className={classes.dialogWrapper}>
				<div className={classes.dialogHeaderWrapper}>
					<Typography className={classes.dialogHeaderText} variant="h2">
						Select Your Fields
					</Typography>
					<IconButton className={classes.dialogExitButton} size="medium" onClick={dialogCloseButtonClickHandler}>
						<DisabledByDefaultIcon fontSize="inherit" />
					</IconButton>
				</div>

				<div className={classes.datasetWrapper}>
					<Grid container className={classes.datasetInfoWrapper}>
						<Grid>
							<img
								style={{ float: 'left' }}
								src={getDatasetLogoSource()}
								alt="Dataset logo."
								className={classes.datasetLogo}
							/>
							<div>
								<Typography variant="h6">{selectedDataset.displayName || selectedDataset.name}</Typography>
								<Typography>
									Select the fields from this dataset you would like to include in you dataspace. You can always add
									more from within your dataspace.
								</Typography>
							</div>
						</Grid>
						{/* If it is a datset with a timeFrame, render the menu to select which timeFrame to use. */}
						{selectedDataset.tags.timeFrame && (
							<Grid item xs={12}>
								<FormControl fullWidth className={classes.seasonSelectForm}>
									<InputLabel id="season-select-input-label">Season</InputLabel>
									<Select
										labelId="season-select-label"
										id="season-select-input"
										value={selectedDataset}
										renderValue={(dataset) => dataset.tags.timeFrame}
										label={'Season'}
										onChange={datasetChangeHandler}
									>
										{timeFrameDatasets.map((dataset) => (
											<MenuItem key={dataset._id} value={dataset}>
												{dataset.tags.timeFrame}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</Grid>
						)}

						<Grid container spacing={0.5} className={classes.datasetFieldsWrapper}>
							{datasetFieldComponents}
						</Grid>
					</Grid>
				</div>

				<Button className={classes.nextButton} onClick={nextButtonClickHandler}>
					Next
				</Button>
			</div>
		</Dialog>
	);
};

export default SelectDatasetFieldsDialog;
