function findFirstNonNull(table, column) {
	for (const row of table) {
		if (row[column] !== null && row[column] !== undefined) {
			return row[column];
		}
	}
	return null;
}

function compareTables(tableA, tableB) {
	const errors = [];

	if (!Array.isArray(tableA) || !Array.isArray(tableB)) {
		errors.push('Both inputs must be arrays of objects.');
		return errors;
	}
	if (tableA.length === 0) {
		errors.push('Table A is empty; cannot determine reference types.');
		return errors;
	}

	// 1. Get columns from the first row of Table A
	const firstRowA = tableA[0];
	const columnsA = Object.keys(firstRowA); // columns from the first row

	// 2. Build a referenceType object: { columnName: "string" | "number" | ... }
	const referenceTypes = {};

	for (const col of columnsA) {
		let val = firstRowA[col];

		// If null/undefined, look for the first non-null value in subsequent rows
		if (val === null || val === undefined) {
			val = findFirstNonNull(tableA, col);
		}

		// If we never found a non-null value, consider it "undefined" or decide your own rule
		if (val === null) {
			referenceTypes[col] = 'undefined';
		} else {
			referenceTypes[col] = typeof val;
		}
	}

	// 3. Verify that Table B has exactly the same columns as Table A's first row
	//    (since all rows in a table have the same columns, we can just check row 0 if it exists)
	if (tableB.length > 0) {
		const firstRowB = tableB[0];
		const columnsB = Object.keys(firstRowB);

		// If the sets of columns differ, that's an immediate error
		if (columnsA.length !== columnsB.length || !columnsA.every((col) => columnsB.includes(col))) {
			errors.push('Tables do not have the same set of columns.');
			return errors;
		}
	}

	// 4. For each column in referenceTypes, check that Table B matches that data type
	for (const [col, refType] of Object.entries(referenceTypes)) {
		// If refType is 'undefined', that means Table A had no real data for this column
		// -> If Table B has any non-null value, mismatch
		if (refType === 'undefined') {
			const firstValInB = findFirstNonNull(tableB, col);
			if (firstValInB !== null) {
				errors.push(`Column "${col}" is always null/undefined in Table A but not in Table B.`);
				return errors;
			}
			// If Table B also has no real data, that's a match (both all-null).
			continue;
		}

		// Otherwise, find the first non-null value in Table B
		const valB = findFirstNonNull(tableB, col);
		if (valB === null) {
			// Table B has no real data, while Table A does
			errors.push(`Column "${col}" has type "${refType}" in Table A but is always null/undefined in Table B.`);
			return errors;
		}

		// Compare types
		const typeB = typeof valB;
		if (typeB !== refType) {
			// Type mismatch
			errors.push(`Column "${col}" type mismatch: expected "${refType}", got "${typeB}".`);
			return errors;
		}
	}

	// 5. If all checks pass
	return errors;
}

export default compareTables;
