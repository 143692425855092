import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import { useContext } from 'react';

const CustomDatasetPreviewTable = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);

	const columns = customDatasetsContext.customDataset.fields?.map((field) => {
		return <Column key={field.name} field={field.name} header={field.displayName} />;
	});

	return <DataTable value={customDatasetsContext.customDataset.rows.slice(0, 3)}>{columns}</DataTable>;
};

export default CustomDatasetPreviewTable;
