import { useContext, useState } from 'react';
import { Dialog, DialogContent, Button, Typography, Box, Backdrop, CircularProgress } from '@mui/material';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import CustomDatasetPreviewTable from './CustomDatasetPreviewTable';
import { updateDataset } from '../../api_helper/api';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';

const OverwriteDatasetDialog = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const [isLoading, setIsLoading] = useState(false);

	const closeDialogHandler = () => {
		customDatasetsContext.setView(null);
		customDatasetsContext.setCustomDataset(null);
	};

	const overwriteDatasetClickHandler = async () => {
		setIsLoading(true);

		try {
			// Update the dataset
			await updateDataset({
				datasetId: customDatasetsContext.customDataset._id,
				rows: customDatasetsContext.customDataset.rows,
			});

			// Show success alert
			snackbarAlertContext.setSnackbarAlert({
				message: 'Dataset updated successfully',
				severity: 'success',
				open: true,
			});

			setIsLoading(false);
			closeDialogHandler();
			customDatasetsContext.setCustomDatasets(undefined);
		} catch (error) {
			// Show error alert
			snackbarAlertContext.setSnackbarAlert({
				message: 'Error updating dataset',
				severity: 'error',
				open: true,
			});
		}
	};

	return (
		Boolean(customDatasetsContext.view === 'overwrite') && (
			<>
				{/* Create Dataset Dialog */}
				<Dialog
					open={true}
					onClose={closeDialogHandler}
					fullWidth
					maxWidth="xl"
					PaperProps={{
						style: {
							maxHeight: '50rem',
						},
					}}
				>
					<DialogContent>
						<Typography variant="h2">Overwrite Dataset</Typography>
						<Typography variant="footnote" sx={{ display: 'block', marginTop: '0.5rem' }}>
							The data previewed below will overwrite the data of the selected dataset.
						</Typography>

						<Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'end', marginTop: '1rem' }}>
							<CustomDatasetPreviewTable />
						</Box>
					</DialogContent>

					{/* Action buttons */}
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'end',
							padding: '1.25rem 1.5rem',
							borderTop: (theme) => `1px solid ${theme.palette.neutral.light}`,
						}}
					>
						<Button variant="outlined" color="neutral" onClick={closeDialogHandler} sx={{ marginRight: '0.75rem' }}>
							Cancel
						</Button>
						<Button variant="contained" color="primary" onClick={overwriteDatasetClickHandler}>
							Overwrite Dataset
						</Button>
					</Box>
				</Dialog>

				{/* Loading Spinner */}
				<Backdrop open={isLoading} sx={{ width: '100vw', height: '100vh', zIndex: '10000', color: '#FFFFFF' }}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</>
		)
	);
};

export default OverwriteDatasetDialog;
