import axios from 'axios';
import { pollDurableFunction } from '../utils/util';
let azureFunctionsServerURL = process.env.REACT_APP_FUNCTIONS_APP_BASE; // Base URL for Functions App host
if (!azureFunctionsServerURL) azureFunctionsServerURL = ''; // Blank prefix will cause axios to proxy to locally hosted functions (Port 7071)

/*
This function deletes a custom column from the DataSpaceSchema.

If the column is referenced by other custom columns, it is not deleted and details
on the referencing columns are returned to the frontend.

@param {String} dataSpaceId: The ID of the DataSpace
@param {String} field: The field referencing the column to be deleted

@returns {Object} {
	isDependency {Boolean}: If the column marked for deletion is referenced by other calculated columns
	dependents [{String}]: Header names of any calculated columns referenced by the column marked for deletion
}
*/
export const deleteCustomColumn = (field, dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/DeleteCustomColumn',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				field,
				dataSpaceId,
			},
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function deletes a custom column from the DataSpaceSchema.

If the column is referenced by other custom columns, it is not deleted and details
on the referencing columns are returned to the frontend.

@param {String} dataSpaceId: The ID of the DataSpace
@param {String} field: The field referencing the column to be deleted

@returns {Object} {
	isDependency {Boolean}: If the column marked for deletion is referenced by other calculated columns
	dependents [{String}]: Header names of any calculated columns referenced by the column marked for deletion
}
*/
export const deleteCustomColumnNew = (field, dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/DeleteCustomColumnNew',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				field,
				dataSpaceId,
			},
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function processes user input specifying a calculated column and returns
the calculated column metadata and row data. 

Additionally, the calculated column "ingredients" are saved to the DataSpace Schema
so they can be rebuilt when retrieving the DataSpace later.

@param {String} expression : 	Expression representing calculated column
@param {String} colName : 		Name of new column
@param {String} dataSpaceId :	ID of DataSpace which owns column


@return {Object} calculatedColumn: {
	calcColDetails {Object}: {
		dataSourceName {String}: 	'Calculated',
		field {String}: 			The field name,
		header {String}: 			The column header name,
		isDependency {Boolean}: 	If other calculated columns use this column as a dependency. Only returns when mode === "edit"
									Used by frontend to determine if DataSpace should be re-retrieved so dependent columns recalculate.							
	},
	calCol [{Object}]: [{
		dynamicallyCreatedField {Number}: fieldValue  // Key will be calculated column field name
	}] 
}
*/

export const calculateColumn = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/CalculateColumn',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function processes user input specifying a calculated column and returns
the calculated column metadata and row data. 

Additionally, the calculated column "ingredients" are saved to the DataSpace Schema
so they can be rebuilt when retrieving the DataSpace later.

@param {String} expression : 	Expression representing calculated column
@param {String} colName : 		Name of new column
@param {String} dataSpaceId :	ID of DataSpace which owns column


@return {Object} calculatedColumn: {
	calcColDetails {Object}: {
		dataSourceName {String}: 	'Calculated',
		field {String}: 			The field name,
		header {String}: 			The column header name,
		isDependency {Boolean}: 	If other calculated columns use this column as a dependency. Only returns when mode === "edit"
									Used by frontend to determine if DataSpace should be re-retrieved so dependent columns recalculate.							
	},
	calCol [{Object}]: [{
		dynamicallyCreatedField {Number}: fieldValue  // Key will be calculated column field name
	}] 
}
*/

export const calculateColumnNew = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/CalculateColumnNew',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function returns a list of Fantasy APIs supported by SportWise and their
associated OAuth redirect links. OAuth links are generated uniquely for each user. This 
information is used in the Fantasy API Connection process.

@return {Object} : {
	api: The API name
	authURL: The authentication redirect URL
}
*/
export const getSupportedFantasyAPIData = () => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetSupportedFantasyAPIData',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function is used to continuously poll the user's subscription object in their
database record until a specified property is set to a specified value. This is useful
when waiting for the user's profile to be updated by webhooks, etc.

@param {Number} pollingDurationSeconds  : How long to poll for before giving up
@param {Number} pollingIntervalSeconds  : How long to wait between each poll
@param {String} propertyKey             : The target property to be polled for in the user's
                                          subscription object.
@param {String} expectedValue           : Function will return true when the target property
                                          is equal to this expected value.
@return {Boolean} expectedValueFounded  : If the expected value was found before timeout
*/
export const pollUserAccountChargifySubscription = (payload) => {
	const { pollingDurationSeconds, pollingIntervalSeconds, propertyKey, expectedValue } = payload;
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/PollUserAccountChargifySubscription',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				pollingDurationSeconds,
				pollingIntervalSeconds,
				propertyKey,
				expectedValue,
			},
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function is used to generate and return a CSV file containing the user's 
current DataSpace. The CSV file is represented as a string. 
@param {String} dataSpaceId: ID of DataSpace to create CSV for
@return {String} : Returns formatted CSV file represented as string.
*/
export const getDataSpaceCSV = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSpaceCSV',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
			response_type: 'blob',
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err.response.data.errorMessage);
			});
	});
};

/*
This function is used to generate and return a CSV file containing the user's 
current DataSpace. The CSV file is represented as a string. 
@param {String} dataSpaceId: ID of DataSpace to create CSV for
@return {String} : Returns formatted CSV file represented as string.
*/
export const getDataSpaceCSVNew = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSpaceCSVNew',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
			response_type: 'blob',
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err.response.data.errorMessage);
			});
	});
};

/*
This function is used to generate and return a CSV file containing the user's 
current DataSpace. The CSV file is represented as a string. 
@param {String} dataSpaceId: ID of DataSpace to create CSV for
@return {String} : Returns formatted CSV file represented as string.
*/
export const updateDataSpaceData = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceData',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
			data: {},
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function is used to purchase a plan in the event that a user has logged in and
their trial has ended or subscription has been cancelled. After the plan has been purchased
the function polls the database until the user's subscription state is "active" and then returns.

@param {String} chargifyToken : Token retrieved from ChargifyJS representing user's payment info
@param {String}	tier          : Tier of subscription chosen by user to be purchased (LITE, FAN, PRO)
@return {Boolean} active      : If the user's RI User Object subscription state has been set to active
                                after purchase.
*/
export const purchasePlanOnChargify = (chargifyToken, tier) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/PurchasePlanOnChargify',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				chargifyToken: chargifyToken,
				tier: tier,
			},
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function is used to extract the user's Billing information from the
Chargify API. 
The information is displayed in the Billing section of the Account tab.
It uses the user's SportWise ID extracted from the JWT.
@returns {Object} billingData : {
  state: The state of the user's subscription in Chargify
  product: The name of the product subscribed to by the user
  billingPortalurl: urlPrefix + A tokenized link to allow the user access to their Billing Portal
}
*/
export const getDataForBillingPage = (dataSourceObjectType) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataForBillingPage',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function is used to verify a reCapthca token to determine if the entity 
making a request is human or a bot. When the token is verified by Google, Google
returns a score between 0 and 1. 0 is most likely a bot while 1 is most likely a human.

@param {String} token: reCaptcha token
@param {Number} threshold: Minimum threshold to judge token score against to grant
 "humanity" to user
 @return{Object} : {
	 passedRecaptchaCheck {Boolean} : If the user passed the reCAPTCHA check
	 msg {String} (OPTIONAL): Message to return if user has not passed reCAPTCHA check 
 } 
*/
export const verifyRecaptchaToken = (token, threshold) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/VerifyRecaptchaToken',
			method: 'GET',
			params: { token, threshold },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
 * Updates the selected fields of user's DataSpace
 * @param {Object} payload:
 * {
 *  dataSpaceId: String
 *  selectedFields: [String]
 * }
 */

export const updateDataSpaceSelectedFields = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceSelectedFields',
			method: 'POST',
			data: payload,
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then(() => {
				resolve();
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
* Retrieves the available fields and associated metadata for a particular DataSource
* @param {String} dataSourceObjectType: The object type of the DataSource to retrieve
* fields from.
* @returns [{Object}] : An array of objects with metadata for each field in that DataSource
    The objects have the following structure:
  {
    field: {String},
    header: {String},
    extendedFieldName: {String}
  }
  ie
  {
    field: "abbrv",
    header: "Team",
    extendedFieldName: "Team_Info__abbrv"
  }
*/

export const getDataSourcesAvailableFields = (dataSourceObjectType) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSourcesAvailableFields',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSourceObjectType: dataSourceObjectType },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Mark Tierney - July 12 2021:
The below was copied from the above and needs to be modified to retrieve the following for Encharge:
* Retrieves the available fields and associated metadata for the Encharge Tag/Tracking associated with RI-348
* @param {String} dataSourceObjectType: The object type of the DataSource to retrieve
* fields from.
* @returns [{Object}] : An array of objects with metadata for each field requested by Dani for new tag creation
    The objects have the following structure:
  {
    UserName: {String},
    FirstName: {String},
    Email: {String},
    UniqueID: {String}
  }
  ie
  {
    username: "author",
    firstname:"Mark",
    email: "mark.tierney@edu.sait.ca"
    _id: ObjectId("5f738b666f30720051f2c1e0")
  }
*/

export const getUserDetailsForEncharge = () => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/getUserDetailsForEncharge',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function gets the info needed to populate the Details Pane
in the DataSpace View.

@param {String} dataSpaceId: The id of the DataSpace
@returns {Object} result: {
  description: {String}
  dataSources: [{Object}] : {
    dataSource: {String},
    api: {String}
  }
  joins: [{Object}]:  {
    primarySource: {String}
    primaryField: {String}
    foreignSource: {String}
    foreignField: {String}
  }
}
*/

export const getInfoForDetailsPane = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetInfoForDetailsPane',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId: dataSpaceId },
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function creates a creates an update DataSpace document whose structure allows for dataset instances
and remove the existing DataSpace. Rows for the new DataSpace document are also stored in blob storage.

@param {String} dataSpaceId: The id of the DataSpace
@returns {String} dataSpaceId: The id of the DataSpace
*/

export const updateDataSpaceStructure = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceStructure',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
		})
			.then((res) => {
				resolve(res.data.dataSpaceId);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function gets the info needed to populate the Details Pane
in the DataSpace View.

@param {String} dataSpaceId: The id of the DataSpace
@returns {Object} result: {
  description: {String}
  dataSources: [{Object}] : {
    dataSource: {String},
    api: {String}
  }
  joins: [{Object}]:  {
    primarySource: {String}
    primaryField: {String}
    foreignSource: {String}
    foreignField: {String}
  }
}
*/

export const getInfoForDetailsPaneNew = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetInfoForDetailsPaneNew',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId: dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function updates the description field of the DataSpaceSchema.

@param {Object} payload: {
  dataSpaceId: The id of the DataSpace
  description {String}: The description of the DataSpace
}
*/

export const updateDataSpaceDescription = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceDescription',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function gets Metadata from a DataSpaceSchema. Metadata
is any field from the DataSpaceSchema we would like to retrieve.

@param {Object} payload: {
  dataSpaceId: The id of the DataSpace
  metadataFields [String]: Array of strings matching fields to be
    retrieved. ex ["lmapiName","selectedLeague","selectedSport"]
}
Elements in metadataFields must match exactly how the field is named in the DataSpaceSchema

@returns {Object} result: An object where each key is a field and the value
is the value of that field.
ex:
{
  lmapiName: "Yahoo! Fantasy Sports",
  selectedLeague: "My League (2020) - Yahoo! Fantasy Sports NFL",
  selectedSport: "NFL"
}
*/

export const getSpecifiedDataSpaceMetadata = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetSpecifiedDataSpaceMetadata',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Deletes a user's DataSpace based on its ID.
Both DataSpaceSchema and JoinedDataSpaces data is removed from database.

@param {String} dataSpaceId: ID of DataSpace to be deleted
*/

export const deleteDataSpaceFromBackend = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/DeleteDataSpace',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId: dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Returns a DataSpace view specified by the user

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    id: The unique ID of the view
  }
*/

export const getSelectedDataSpaceView = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetSelectedDataSpaceView',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Returns a DataSpace view specified by the user

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    id: The unique ID of the view
  }
*/

export const getSelectedDataSpaceViewNew = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetSelectedDataSpaceViewNew',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Retrieves list of user's saved custom views belonging to DataSpace
@param String dataSpaceId: The DataSpace's id
@return {Object Array} views: [{name: String, id: String}]
*/

export const getDataSpaceViews = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSpaceViews',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId: dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Retrieves list of user's saved custom views belonging to DataSpace
@param String dataSpaceId: The DataSpace's id
@return {Object Array} views: [{name: String, id: String}]
*/

export const getDataSpaceViewsNew = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSpaceViewsNew',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId: dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Adds a custom view to the DataSpace

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    view: {name: String, internalProperties: Object, externalProperties: Object}
  }
*/

export const appendToDataSpaceViews = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/AppendToDataSpaceViews',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Adds a custom view to the DataSpace

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    view: {name: String, internalProperties: Object, externalProperties: Object}
  }
*/

export const appendToDataSpaceViewsNew = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/AppendToDataSpaceViewsNew',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateDataSpaceViews = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceViews',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Retrieves DataSpace State from database.

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    mode: "internalState" or "toggledColumnsState" - Which type of state you want to retrieve
  }
@returns {
  ***(Based on mode selection)***
  internalState {Object} : State object for use in PrimeFaces DataTable customRestoreState callback
  toggledColumnsState [String] :  Array of header values representing visible columns
}
*/

export const retrieveDataSpaceState = (dataSpaceId, mode) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/RetrieveDataSpaceState',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				dataSpaceId: dataSpaceId,
				mode: mode,
			},
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Retrieves DataSpace State from database.

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    mode: "internalState" or "toggledColumnsState" - Which type of state you want to retrieve
  }
@returns {
  ***(Based on mode selection)***
  internalState {Object} : State object for use in PrimeFaces DataTable customRestoreState callback
  toggledColumnsState [String] :  Array of header values representing visible columns
}
*/

export const retrieveDataSpaceStateNew = (dataSpaceId, mode) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/RetrieveDataSpaceStateNew',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				dataSpaceId: dataSpaceId,
				mode: mode,
			},
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Updates DataSpace State in database.

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    mode: "internalState" or "toggledColumnsState" - Type of state to be updated.

    *** Include only one state type per function call ***
    internalState {Object} : State object provided by PrimeFaces DataTable
    toggledColumnsState [String] : Array of header values representing visible columns
    headerNamesState {field: String, header: String} : Tuple representing named
      header and their corresponding field. Only one header name may be updated at a time
      at a time.
  }
*/

export const updateDataSpaceState = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceState',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Updates DataSpace State in database.

@param {Object} payload: {
    dataSpaceId: The DataSpace's id
    mode: "internalState" or "toggledColumnsState" - Type of state to be updated.

    *** Include only one state type per function call ***
    internalState {Object} : State object provided by PrimeFaces DataTable
    toggledColumnsState [String] : Array of header values representing visible columns
    headerNamesState {field: String, header: String} : Tuple representing named
      header and their corresponding field. Only one header name may be updated at a time
      at a time.
  }
*/

export const updateDataSpaceStateNew = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceStateNew',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function returns a list of the user's saved DataSpaces to be included
in the My DataSpaces list.

Currently we are only displaying "saved" DataSpaces. Access to
DataSpace drafts from My DataSpaces List will be implemented in
future stories.

@return {Object Array} dataSpaces: Array of objects containing
data required for My DataSpaces List (_id, dataSpaceName, dataSpaceType)
*/
export const getDataSpaces = (dataSpaceId, userId, isFeatured, datasetId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSpaces',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				dataSpaceId,
				userId,
				isFeatured,
				datasetId,
			},
		})
			.then((res) => {
				if (dataSpaceId) {
					resolve(res);
				} else {
					resolve(res.data.dataSpaces);
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getDatasource = (datasourceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + `/api/getDatasource`,
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { datasourceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
This function finds the DataSpace document with the id passed to the 
function. It then replaces the name property of the document with the name 
value passed to the function.

@return {Object} : {
  data: 'Successfully updated DataSpace name',
}
*/
export const updateDataSpaceName = (dataSpaceId, dataSpaceName) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + `/api/updateDataSpaceName`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId, dataSpaceName },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Duplicate DataSpace document and its corresponding JoinedDataSpace document.
Assigns the duplicated JoinedDataSpace document to the new DataSpace document.
@param {String} dataSpaceId: The id of the DataSpace to be duplicated.
@return {Object} : {
  data: undefined (No data is returned from this function.)
}
*/

export const duplicateDataSpace = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + `/api/DuplicateDataSpace`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Duplicate DataSpace document and its corresponding JoinedDataSpace document.
Assigns the duplicated JoinedDataSpace document to the new DataSpace document.
@param {String} dataSpaceId: The id of the DataSpace to be duplicated.
@return {Object} : {
  data: undefined (No data is returned from this function.)
}
*/

export const duplicateDataSpaceNew = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + `/api/DuplicateDataSpaceNew`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Deletes the DataSpace document and the blob file with its data.
@param {String} dataSpaceId: The id of the DataSpace to be deleted.
@return {Object} : {
  data: undefined (No data is returned from this function.)
}
*/

export const deleteDataSpaceNew = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + `/api/DeleteDataSpaceNew`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Retrieves joined and saved DataSpace data from database
@param {String} dataSpaceId: The id of the DataSpace's data being retrieved.
@return {Object} : {
  data: Array of row data
  columns: Array of column data with fields and headers
    (Any columns named by user will have that name reflected in returned header)
}
*/

export const retrieveJoinedDataSpaceData = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/RetrieveJoinedDataSpaceData',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId: dataSpaceId },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
Creates joined DataSpace based on user's join specifications
in Identify Connect.
Two modes: Preview and Save

@param {String} dataSpaceId: The ID of the DataSpace
@param {String} mode: Selects function mode
  - Available modes 'preview', 'save', 'refresh'

Preview Mode:
@returns {Object} res: {
  data: First 3 rows of joined data
  columns: Unique columns in joined data
}

Refresh Mode (Save and return all data):
@return {Object} res: {
  data: All rows of joined data
  columns: Unique columns in joined data
}

Save Mode:
Does not return data.
Saves joined data in JoinedDataSpaces collection
*/
export const generateJoinedDataSpace = async (dataSpaceId, mode) => {
	try {
		// Makes request and returns object contain status uri.
		const axiosRes = await axios({
			url: azureFunctionsServerURL + '/api/orchestrators/GenerateJoinedDataSpaceOrchestrator',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: { dataSpaceId, mode },
		});

		const pollResult = await pollDurableFunction(axiosRes.data.statusQueryGetUri, 10000);
		if (pollResult.data.runtimeStatus === 'Failed') {
			throw pollResult.data.output;
		}
		return pollResult;
	} catch (e) {
		throw e;
	}
};

/*
Updates any Yahoo data user has selected in Data Selection
@param {Object} payload :
    {
      api: API Name
      league_key: League Key
      lmapiObjects: Specified LMAPI Objects
      sport: Sport
    }
*/
export const yahooUploadUserSelectedData = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/YahooUploadUserSelectedData',
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
 * Updates the join state of user's DataSpace
 * @param {String} jwt : The user's JWT
 * @param {Object} body
 * {
 *  dataSpaceId
 *  joinData
 * }
 */
export const updateDataSpaceJoins = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceJoins',
			method: 'POST',
			data: payload,
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then(() => {
				resolve();
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
function: updatePassword
This function is used to update the user's password

@param {String} payload: The data to be persisted to the user profile

The payload is described by the object below.
The objects are generic in nature but will include the
field names and values from the form

payload : {
  Values[
    { key :
      value :
    }
  ]
}
*/
export const updatePassword = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdatePassword',
			method: 'PUT',
			data: payload,
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err.response.data);
				reject(err.response);
			});
	});
};

/*
function: updateUserProfile
This function is used to persist the user's user profile to the database

@param {String} payload: The data to be persisted to the user profile

The payload is described by the object below

payload : {
  firstname:
  lastname:
  username:
  email:
  phone:
}
*/
export const updateUserProfile = async (payload) => {
	try {
		const res = await axios({
			url: azureFunctionsServerURL + '/api/UpdateUserProfile',
			method: 'PUT',
			data: payload,
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		});

		if (res.data.userProfileImageUploadUrl) {
			const imageRes = await fetch(payload.croppedProfileImage);
			const imageBlob = await imageRes.blob();
			const imageArrayBuffer = await imageBlob.arrayBuffer();
			const imageBinary = new Uint8Array(imageArrayBuffer);

			await axios({
				url: res.data.userProfileImageUploadUrl,
				method: 'PUT',
				data: imageBinary,
				headers: {
					'api-key': process.env.REACT_APP_SPORTWISE_API_KEY,
					'x-ms-blob-type': 'BlockBlob',
					'Content-Type': 'multipart/form-data',
				},
			});
		}

		return res;
	} catch (e) {
		throw e;
	}
};

/*
 * Get the logged in User's profile
 */
export const getUserProfile = () => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetUserProfile',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject();
			});
	});
};

/*
 * Updates Yahoo! Fantasy Sport Leagues for user specified by JWT
 * @param {String} jwt : The user's JWT
 */
export const yahooUpdateLeagues = (jwt) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/YahooUpdateLeagues',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: jwt },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
 * Retrieves system data specified by name
 * @param {String} name : The name or identifier of the system data
 */
export const getSystemData = (name) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetSystemData',
			method: 'GET',
			params: { name: name },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject();
			});
	});
};

/*
 * Get list of Fantasy APIs user is connected to
 * @param {String} jwt : The user's JWT
 */
export const getUsersConnectedFantasyProviders = () => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetUsersConnectedFantasyProviders',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject();
			});
	});
};

/*
function: SaveDataSpaceDataSelections
This function is used to persist the user's Data selections to the
database.

dataSpaceId must be the first value in the payload.

One or more data categories may be included in the payload. Data categories
must be named as follows:

payload : {
  dataSpaceId: String
  selectedLeague: String
  selectedSport: String
  leagueObjects: [String]
  generalData: [String]
  gameData: [String]
  noveltyData: [String]
  lmapiObjectTypes: [String]
  lmapiName: String
}
*/
export const updateDataSpaceSelections = (payload) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpaceSelections',
			method: 'POST',
			data: payload,
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then(() => {
				resolve();
			})
			.catch((err) => {
				reject(err);
			});
	});
};

/*
function: createDataSpaceSchemaWithName
This function creates a dataSpaceSchema object in the database
with a name.
@param {String} jwt: user's JWT
@param {String} name: name of DataSpace
*/
export const createDataSpaceSchemaWithName = (jwt, name) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/CreateDataSpaceSchemaWithName',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: jwt },
			params: { name: name },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

// Return dummy list of available sports
export const getAvailableSports = () => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetAvailableSports',
			method: 'GET',
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject();
			});
	});
};

/*
 * Retrieve League information for user specified by JWT
 * Leagues used in Data Selection/Upload
 * @param {String} jwt : The user's JWT
 */
export const getUsersLeagues = () => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetUsersLeagues',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject();
			});
	});
};

/*
 * Return available DataSources based on selected LMAPI and Sport.
 * @param {String} league: Name of LMAPI associated with league (ie Yahoo! Fantasy Sports)
 * @param {String} sport: Sport associated with user's selection (ie NFL, MLB, etc)
 */
export const getLeagueObjects = (league, sport) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetLeagueObjectList',
			method: 'GET',
			params: { league: league, sport: sport },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};

/*
 * Return a list of DataSources from Reliant based on user's selected sport.
 * @param {String} sport: Selected sport (ie NFL, MLB, etc)
 */
export const getAPIObjectList = (sport) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetAPIObjectList',
			method: 'GET',
			params: { sport: sport },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject();
			});
	});
};

/*  This function takes a userID and authProvider and determines if
    the user is logged in with that auth provider.
*/
export const checkThirdPartyAuthenticationStatus = (authProvider) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/CheckThirdPartyAuthenticationStatus',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { authProvider: authProvider },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUserIdFromJwt = (jwt) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/getUserIdFromJwt',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: jwt },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(console.log('An error occured getting the User from JWT: ' + err));
			});
	});
};

/*  This function takes an e-mail and determines if it is
    already registered within the database. If so API
    returns a message stating as such in the format {msg: 'error'}
*/
export const checkEmailRegistered = (email) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/CheckEmailRegistered',
			method: 'GET',
			params: { email: email },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(console.log(err));
			});
	});
};

/*  This function takes a username and determines if it is
    already registered within the database. If so  API
    returns a message stating as such in the format {msg: 'error'}
*/
export const checkUsernameRegistered = (username) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/CheckUsernameRegistered',
			method: 'GET',
			params: { username: username },
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				reject(console.log(err));
			});
	});
};

/*  This function takes a payload of registration data and uses it
    to create a user in the database.
*/
export const createUser = (payload) => {
	return new Promise((resolve, reject) => {
		// Create and upload user

		// Make request to save user to database
		axios({
			url: azureFunctionsServerURL + '/api/CreateUser',
			method: 'POST',
			data: payload,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log('api call');
				console.log(err.response.data);
				reject(err.response);
			});
	});
};

/* This function takes a payload of the users email address and uses
    it to request a password reset email
*/
export const ForgottenPasswordRequest = (payload) => {
	return new Promise((resolve, reject) => {
		// Make request to the resetpassword function to send a password reset request
		axios({
			url: azureFunctionsServerURL + '/api/ForgottenPassword',
			method: 'POST',
			data: payload,
		})
			.then(() => {
				resolve('Reset Password email sent successfully.');
			})
			.catch(() => {
				reject('Error sending password reset email to server');
			});
	});
};

/* This function takes a payload of the users reset token and
  new password and resets the password
*/
export const ResetPasswordRequest = (payload) => {
	return new Promise((resolve, reject) => {
		// Make request to the resetpassword function to send a password reset request
		axios({
			url: azureFunctionsServerURL + '/api/ResetPassword',
			method: 'POST',
			data: payload,
		})
			.then((res) => resolve(res))
			.catch((err) => {
				console.log(err.response.data);
				reject(err.response);
			});
	});
};

/*  This function takes in the user's e-mail and password. The credentials
    are used in an Azure function to first determine if an account
    exists with that e-mail, and then if the password matches the account's
    listed password.

    If the login info is correct the function returns a JWT with the User's
    role and id encoded within.

    If info is incorrect function returns error in res body:
    { msg: " Email or password incorrect."}
*/
export const signIn = (payload) => {
	return new Promise((resolve, reject) => {
		// Create and upload user

		// Make request to save user to database
		axios({
			url: azureFunctionsServerURL + '/api/SignIn',
			method: 'POST',
			data: payload,
		})
			.then((res) => resolve(res))
			.catch((err) => reject(err));
	});
};

export const getDatasources = async (sport, apis) => {
	try {
		const apisQuery = apis.reduce((accumulator, api) => {
			api = api.replaceAll(' ', '+');
			return accumulator + `&apis=${api}`;
		}, '');

		// Makes request and returns object contain status uri.
		const axiosRes = await axios({
			url: azureFunctionsServerURL + `/api/GetDatasources?sport=${sport}${apisQuery}`,
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		});

		return axiosRes;
	} catch (e) {
		throw e;
	}
};

export const getLatestSeasonDatasources = async (sport, apis) => {
	try {
		const apisQuery = apis.reduce((accumulator, api) => {
			api = api.replaceAll(' ', '+');
			return accumulator + `&apis=${api}`;
		}, '');

		// Makes request and returns object contain status uri.
		const axiosRes = await axios({
			url: azureFunctionsServerURL + `/api/getLatestSeasonDatasources?sport=${sport}${apisQuery}`,
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		});

		return axiosRes;
	} catch (e) {
		throw e;
	}
};

export const getDatasets = async ({ groups, userId }) => {
	try {
		let url = azureFunctionsServerURL + '/api/GetDatasets?';

		if (groups) {
			url += `&groups=${groups.join(',')}`;
		}

		if (userId) {
			url += `&userId=${userId}`;
		}

		// Makes request and returns object contain status uri.
		const axiosRes = await axios({
			url,
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		});

		return axiosRes;
	} catch (e) {
		throw e;
	}
};

export const getDatasourceObjectTypeDatasources = async (datasourceObjectType) => {
	try {
		// Makes request and returns object contain status uri.
		const axiosRes = await axios({
			url:
				azureFunctionsServerURL +
				`/api/getDatasourceObjectTypeDatasources?datasourceObjectType=${datasourceObjectType}`,
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
		});

		return axiosRes;
	} catch (e) {
		throw e;
	}
};

export const createDataSpace = async ({
	name,
	creationDate,
	lastRefreshedDate,
	toggledColumnsState,
	views,
	calculatedColumns,
	headerNamesState,
	createdBy,
	joinData,
	isPublic,
	datasets,
	columnMetadata,
	internalState,
	originalDataSpace,
	croppedDataSpaceImage,
	dataSpaceImageUrl,
	originalAuthor,
	publishedDate,
}) => {
	try {
		// Create DataSpace document
		const axiosRes = await axios({
			url: azureFunctionsServerURL + `/api/CreateDataSpace`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: {
				name,
				creationDate,
				lastRefreshedDate,
				toggledColumnsState,
				views,
				calculatedColumns,
				headerNamesState,
				createdBy,
				joinData,
				isPublic,
				datasets,
				columnMetadata,
				internalState,
				originalDataSpace,
				croppedDataSpaceImage,
				dataSpaceImageUrl,
				originalAuthor,
				publishedDate,
			},
		});

		return axiosRes.data.dataSpaceId;
	} catch (e) {
		throw e;
	}
};

export const updateDataSpace = async (
	userId,
	dataSpaceId,
	name,
	views,
	selectedDatasets,
	joinData,
	lastRefreshedDate,
	calculatedColumns,
	headerNamesState,
	originalAuthor,
) => {
	try {
		// Makes request and returns object contain status uri.
		const axiosRes = await axios({
			url: azureFunctionsServerURL + `/api/UpdateDataSpace`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: {
				userId,
				name,
				views,
				selectedDatasets,
				joinData,
				lastRefreshedDate,
				calculatedColumns,
				headerNamesState,
				originalAuthor,
			},
			params: {
				dataSpaceId,
			},
		});

		return axiosRes.data.dataSpace;
	} catch (e) {
		throw e;
	}
};

/* This function gets a DataSpace document from the database using its id.
 */
export const getDataSpaceNew = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		// Make request to the resetpassword function to send a password reset request
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSpaceNew',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				dataSpaceId,
			},
		})
			.then((res) => resolve(res))
			.catch((err) => {
				console.log(err.response.data);
				reject(err.response);
			});
	});
};

/* This function gets the rows for a DataSpace from blob storage using the DataSpace's id. */
export const getDataSpaceRows = (dataSpaceId) => {
	return new Promise((resolve, reject) => {
		axios({
			url: azureFunctionsServerURL + '/api/GetDataSpaceRows',
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: {
				dataSpaceId,
			},
		})
			.then((res) => resolve(res))
			.catch((err) => {
				console.log(err.response.data);
				reject(err.response);
			});
	});
};

// Updates DataSpace with the fields passed to the function
export const updateDataSpace_v2 = async ({ dataSpaceId, updatedFields }) => {
	try {
		const res = await axios({
			url: azureFunctionsServerURL + '/api/UpdateDataSpace_v2',
			method: 'PATCH',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { dataSpaceId },
			data: {
				...updatedFields,
			},
		});

		// Upload DataSpace image to blob storage (if applicable)
		if (res.data.dataSpaceImageUploadUrl) {
			const imageRes = await fetch(updatedFields.croppedDataSpaceImage);
			const imageBlob = await imageRes.blob();
			const imageArrayBuffer = await imageBlob.arrayBuffer();
			const imageBinary = new Uint8Array(imageArrayBuffer);

			await axios({
				url: res.data.dataSpaceImageUploadUrl,
				method: 'PUT',
				data: imageBinary,
				headers: {
					'api-key': process.env.REACT_APP_SPORTWISE_API_KEY,
					'x-ms-blob-type': 'BlockBlob',
					'Content-Type': 'multipart/form-data',
				},
			});
		}

		return res.data.dataSpace;
	} catch (e) {
		return e;
	}
};

// Updates User Document with the fields passed to the function
export const updateUser = async ({ userId, updatedFields }) => {
	try {
		return (
			await axios({
				url: azureFunctionsServerURL + '/api/UpdateUser',
				method: 'PATCH',
				headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
				params: { userId },
				data: {
					...updatedFields,
				},
			})
		).data;
	} catch (e) {
		throw e;
	}
};

// Fetches fantasy leagues the user belongs to and updates the database
export const updateUserLeagues = async ({ userId }) => {
	try {
		return (
			await axios({
				url: azureFunctionsServerURL + '/api/UpdateUserLeagues',
				method: 'POST',
				headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
				params: { userId },
			})
		).data;
	} catch (e) {
		return e;
	}
};

export const createDataset = async ({ name, sport, tags, fields, rows }) => {
	try {
		const axiosRes = await axios({
			url: azureFunctionsServerURL + `/api/CreateDataset`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			data: {
				name,
				sport,
				tags,
				fields,
				rows,
			},
		});

		return axiosRes.data.datasetId;
	} catch (e) {
		throw e;
	}
};

export const deleteDataset = async (datasetId) => {
	try {
		const res = await axios({
			url: azureFunctionsServerURL + `/api/DeleteDataset`,
			method: 'DELETE',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { datasetId },
		});

		return res.data;
	} catch (e) {
		throw e;
	}
};

export const getDatasetRows = async (datasetId) => {
	try {
		const res = await axios({
			url: azureFunctionsServerURL + `/api/GetDatasetRows`,
			method: 'GET',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { datasetId },
		});

		return res.data?.rows;
	} catch (e) {
		throw e;
	}
};

export const updateDataset = async ({ datasetId, name, tags, fields, rows }) => {
	try {
		const res = await axios({
			url: azureFunctionsServerURL + `/api/UpdateDataset`,
			method: 'POST',
			headers: { 'api-key': process.env.REACT_APP_SPORTWISE_API_KEY, jwt: localStorage.getItem('jwt') },
			params: { datasetId },
			data: { name, tags, fields, rows },
		});

		return res.data;
	} catch (e) {
		throw e;
	}
};
