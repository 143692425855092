import { useContext } from 'react';
import { ListItemIcon, MenuItem, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';

const DeleteDatasetButton = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);

	const deleteDatasetButtonClickHandler = () => {
		customDatasetsContext.setView('delete');
	};

	return (
		<MenuItem sx={{ color: (theme) => `${theme.palette.danger.main}` }} onClick={deleteDatasetButtonClickHandler}>
			<ListItemIcon sx={{ color: 'inherit' }}>
				<SvgIcon fontSize="1rem" component={DeleteIcon} />
			</ListItemIcon>

			<Typography variant="body1" color="inherit">
				Delete
			</Typography>
		</MenuItem>
	);
};

export default DeleteDatasetButton;
