import Papa from 'papaparse';

const parseCsvFile = (file, config = {}) => {
	return new Promise((resolve, reject) => {
		Papa.parse(file, {
			...config,
			complete: (results) => {
				resolve(results);
			},
			error: (err) => {
				reject(err);
			},
		});
	});
};

export default parseCsvFile;
