import { useContext, useRef } from 'react';
import { ListItemIcon, ListItemText, MenuItem, SvgIcon } from '@mui/material';
import { ReactComponent as OverwriteIcon } from '../../assets/icons/overwrite.svg';
import { getDatasetRows } from '../../api_helper/api';
import parseCsvFile from './parseCsv';
import sanitizeFieldName from '../../utils/sanitizeFieldName';
import validateJson from './validateJson';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import compareJsonRows from './compareJsonRows';

const OverwriteDataButton = () => {
	const fileInputRef = useRef(null);

	const customDatasetsContext = useContext(CustomDatasetsContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const handleFileChange = async (event) => {
		try {
			// This will be triggered once the user has selected a file
			const file = event.target.files[0];

			if (!file) {
				return;
			}

			// Initialize jsonData, which holds the rows data
			let jsonData;

			// Convert data to JSON if it's a CSV file
			if (file.name.toLowerCase().endsWith('.csv')) {
				// Wait for the parsing to complete
				jsonData = (
					await parseCsvFile(file, {
						dynamicTyping: true,
						header: true,
					})
				).data;
			} else if (file.name.toLowerCase().endsWith('.json')) {
				// Read the file as JSON
				jsonData = JSON.parse(await file.text());
			}

			// Replace all keys with sanitized keys
			jsonData = jsonData.map((row) => {
				const newRow = {};

				Object.keys(row).forEach((key) => {
					newRow[sanitizeFieldName(key)] = row[key];
				});

				return newRow;
			});

			// Validate the CSV data
			const errors = validateJson(jsonData);

			// If there are errors, display them to the user
			if (errors.length) {
				snackbarAlertContext.setSnackbarAlert({
					msg: errors.join('\n'),
					severity: 'error',
					autoHide: 5000,
					isSnackbarOpen: true,
				});

				// Reset the input's value so choosing the same file again triggers onChange
				event.target.value = '';

				return;
			}

			// Fetch the current dataset rows
			const currentRows = await getDatasetRows(customDatasetsContext.customDataset._id);

			// Validate that the new data has the same data type for each column as the current data
			const compareTablesErrors = compareJsonRows(currentRows, jsonData);

			// If there are errors, display them to the user
			if (compareTablesErrors.length) {
				snackbarAlertContext.setSnackbarAlert({
					msg: compareTablesErrors.join('\n'),
					severity: 'error',
					autoHide: 5000,
					isSnackbarOpen: true,
				});
				event.target.value = '';
				return;
			}

			// Set the parsed data in the context
			customDatasetsContext.setCustomDataset((prevState) => ({ ...prevState, rows: jsonData }));

			customDatasetsContext.setView('overwrite');

			// Reset the input's value so choosing the same file again triggers onChange
			event.target.value = '';
		} catch (e) {
			snackbarAlertContext.setSnackbarAlert({
				msg: 'There was an error overwriting the data.',
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});
		}
	};

	return (
		<>
			<MenuItem onClick={() => fileInputRef.current.click()}>
				<ListItemIcon>
					<SvgIcon fontSize="1rem" component={OverwriteIcon} />
				</ListItemIcon>
				<ListItemText>Overwrite</ListItemText>
			</MenuItem>

			<input
				ref={fileInputRef}
				type="file"
				accept=".csv, .json"
				onChange={handleFileChange}
				style={{ display: 'none' }}
			/>
		</>
	);
};

export default OverwriteDataButton;
