import { Box, Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import { useContext, useEffect, useState } from 'react';
import { getDataSpaces, deleteDataset } from '../../api_helper/api';

const DeleteDatasetDialog = () => {
	const customDatasetsContext = useContext(CustomDatasetsContext);

	const [dataSpaces, setDataSpaces] = useState(undefined);
	const [isLoading, setIsLoading] = useState(false);

	const closeDialogHandler = () => {
		customDatasetsContext.setView(null);
		customDatasetsContext.setCustomDataset(null);
	};

	const deleteDatasetConfirmationButtonClickHandler = async () => {
		setIsLoading(true);

		await deleteDataset(customDatasetsContext.customDataset._id);

		// Remove loading animation
		setIsLoading(false);

		// Close the dialog
		closeDialogHandler();

		// Refresh the list
		customDatasetsContext.setCustomDatasets(undefined);
	};

	useEffect(() => {
		const getDataSpacesWithDataset = async () => {
			const dataSpaces = await getDataSpaces(null, null, null, customDatasetsContext.customDataset?._id);
			setDataSpaces(dataSpaces);
		};

		if (customDatasetsContext.view === 'delete') {
			getDataSpacesWithDataset();
		}
	}, [customDatasetsContext.customDataset, customDatasetsContext.view]);

	return (
		Boolean(customDatasetsContext.view === 'delete') && (
			<Dialog open={true} onClose={closeDialogHandler}>
				<DialogTitle>{'Delete Dataset'}</DialogTitle>
				{dataSpaces ? (
					<DialogContent>
						{dataSpaces.length === 0 ? (
							<DialogContentText>Are you sure you want to delete this dataset?</DialogContentText>
						) : (
							<>
								<DialogContentText
									sx={{ color: (theme) => `${theme.palette.danger.main}`, margin: '0.75rem 0 0.5rem 0' }}
								>
									Dataset cannot be deleted as it is used by the following DataSpaces:
								</DialogContentText>

								<ul style={{ paddingLeft: '1.5rem', margin: '0' }}>
									{dataSpaces.map((dataSpace) => (
										<li>
											<DialogContentText key={dataSpace._id} variant="subtitle1" sx={{ textAlign: 'start' }}>
												{dataSpace.name}
											</DialogContentText>
										</li>
									))}
								</ul>
							</>
						)}
					</DialogContent>
				) : (
					<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', margin: '1rem 0' }}>
						<CircularProgress />
					</Box>
				)}

				{/* Action buttons */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'end',
						padding: '1.25rem 1.5rem',
						borderTop: (theme) => `1px solid ${theme.palette.neutral.light}`,
					}}
				>
					<Button
						variant="outlined"
						color="neutral"
						onClick={closeDialogHandler}
						sx={{ marginRight: '0.75rem' }}
						disabled={isLoading}
					>
						Cancel
					</Button>

					<Button
						variant="contained"
						color="danger"
						onClick={deleteDatasetConfirmationButtonClickHandler}
						disabled={isLoading || dataSpaces?.length > 0}
					>
						Delete Dataset
					</Button>
				</Box>
			</Dialog>
		)
	);
};

export default DeleteDatasetDialog;
