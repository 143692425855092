/*
Header component for Datatable columns. Each column has a header.

Functions:
Context Menu with Hide, Rename, Edit
Display header name
Display Datasource name
*/

import React, { useState, useContext } from 'react';
import { DataSpaceViewContext } from '../../my_dataspaces/context/DataSpaceViewContext';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as ContextMenuIcon } from '../../../assets/icons/more-vertical.svg';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { deleteCustomColumnNew } from '../../../api_helper/api';
import { GlobalAlertContext } from '../../../GlobalAlertContext';
import { trackEvent } from '../../../utils/eventTracking';
import { UserProfileContext } from '../../../UserProfileContext';

const useStyles = makeStyles(() => ({
	// Browser compatibility for pointerEvents may not be widespread
	// Review at https://caniuse.com/pointer-events
	root: {
		pointerEvents: 'none',
	},
	menu: {
		pointerEvents: 'auto',
		float: 'right',
	},
}));

export default function ColumnHeader(props) {
	const classes = useStyles();

	const user = useContext(UserProfileContext)[0];

	const { header, field, dataSourceName, description } = props.column;
	const dataSpaceViewContext = useContext(DataSpaceViewContext);
	const dataSpaceId = dataSpaceViewContext.dataSpaceId[0];
	const setAlertOpen = useContext(GlobalAlertContext).globalAlertOpen[1];
	const setAlertSettings = useContext(GlobalAlertContext).globalAlertSettings[1];

	const dataset = dataSpaceViewContext.dataSpace.datasets.find((dataset) => dataset._id === field.substr(-24));

	// Context Menu
	const [anchorEl, setAnchorEl] = useState(null);
	const handleContextMenuClick = (e) => setAnchorEl(e.currentTarget);
	const handleContextMenuClose = () => setAnchorEl(null);

	// Help Description Dialog
	const [showHelpDialogOpen, setShowHelpDialogOpen] = useState(false);
	const handleClickShowHelpDialogOpen = () => {
		setShowHelpDialogOpen(true);
		handleContextMenuClose();
	};
	// End context menu functions

	return (
		<div className={classes.root}>
			<Typography variant="body1">{header}</Typography>
			<Typography variant="caption">
				{dataset
					? `${dataset.displayName || dataset.name} ${dataset.tags.timeFrame || ''}` + ` (${dataset._id.substring(21)})`
					: 'Calculated'}
			</Typography>
			<ContextMenuIcon className={classes.menu} onClick={handleContextMenuClick} fill="#70cde5" />

			<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleContextMenuClose}>
				<MenuItem
					onClick={() => {
						props.onHideColumn(field);
					}}
				>
					Hide
				</MenuItem>
				{props.frozenColumns.includes(field) ? (
					<MenuItem
						onClick={() => {
							props.onUnfreezeColumn(field);
						}}
					>
						Unpin
					</MenuItem>
				) : (
					<MenuItem
						onClick={() => {
							props.onFreezeColumn(field);
						}}
					>
						Pin
					</MenuItem>
				)}
				{description && <MenuItem onClick={handleClickShowHelpDialogOpen}>What's this?</MenuItem>}
			</Menu>

			{/* HELP DIALOG */}
			<Dialog open={showHelpDialogOpen} onClose={handleContextMenuClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="alert-dialog-title">{'Definition of ' + header + ' Statistic'}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<p>{description}</p>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowHelpDialogOpen(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
