import React, { useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';

function SearchBar() {
	const customDatasetsContext = useContext(CustomDatasetsContext);

	const handleSearchChange = (event) => {
		customDatasetsContext.setSearchQuery(event.target.value);
	};

	return (
		<TextField
			sx={{ width: '100%', maxWidth: '24rem' }}
			variant="outlined"
			value={customDatasetsContext.searchQuery}
			onChange={handleSearchChange}
			placeholder="Search..."
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<SearchIcon />
					</InputAdornment>
				),
			}}
		/>
	);
}

export default SearchBar;
