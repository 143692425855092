import { useRef, useContext } from 'react';
import validateJson from './validateJson';
import SnackbarAlertContext from '../../context/SnackbarAlertContext';
import CustomDatasetsContext from '../../context/CustomDatasetsContext';
import parseCsvFile from './parseCsv';
import sanitizeFieldName from '../../utils/sanitizeFieldName';
import { Button } from '@mui/material';

const CreateDatasetButton = () => {
	const fileInputRef = useRef(null);

	const customDatasetsContext = useContext(CustomDatasetsContext);
	const snackbarAlertContext = useContext(SnackbarAlertContext);

	const handleFileChange = async (event) => {
		// This will be triggered once the user has selected a file
		const file = event.target.files[0];

		if (!file) {
			return;
		}

		// Initialize jsonData, which holds the rows data
		let jsonData;

		// Convert data to JSON if it's a CSV file
		if (file.name.toLowerCase().endsWith('.csv')) {
			// Wait for the parsing to complete
			jsonData = (
				await parseCsvFile(file, {
					dynamicTyping: true,
					header: true,
				})
			).data;
		} else if (file.name.toLowerCase().endsWith('.json')) {
			// Read the file as JSON
			jsonData = JSON.parse(await file.text());
		}

		const fields = Object.keys(jsonData[0]).map((header) => ({
			name: sanitizeFieldName(header),
			displayName: header,
			isJoinField: false,
			isExcluded: false,
		}));

		// Replace all keys with sanitized keys
		jsonData = jsonData.map((row) => {
			const newRow = {};

			Object.keys(row).forEach((key) => {
				newRow[sanitizeFieldName(key)] = row[key];
			});

			return newRow;
		});

		// Validate the CSV data
		const errors = validateJson(jsonData);

		// If there are errors, display them to the user
		if (errors.length) {
			snackbarAlertContext.setSnackbarAlert({
				msg: errors.join('\n'),
				severity: 'error',
				autoHide: 5000,
				isSnackbarOpen: true,
			});

			// Reset the input's value so choosing the same file again triggers onChange
			event.target.value = '';

			return;
		}

		// Set the parsed data in the context
		customDatasetsContext.setCustomDataset({ rows: jsonData, fields });

		customDatasetsContext.setView('create');

		// Reset the input's value so choosing the same file again triggers onChange
		event.target.value = '';
	};

	return (
		<>
			{/* Create file when clicked using hidden file input */}
			<Button variant="contained" style={{ margin: '0.5rem 0' }} onClick={() => fileInputRef.current.click()}>
				Upload Private Dataset
			</Button>

			<input
				ref={fileInputRef}
				type="file"
				accept=".csv, .json"
				onChange={handleFileChange}
				style={{ display: 'none' }}
			/>
		</>
	);
};

export default CreateDatasetButton;
